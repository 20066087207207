import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useImage } from './ImageContext';
import AddActivity from './AddActivity';
import EditActivity from './EditActivity';
import { FaTrashAlt, FaEdit, FaPlus } from 'react-icons/fa';

const api_url = `${API_URL}/activity/getAllActivities`;

function Activities() {
  const [activities, setActivities] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [currentActivity, setCurrentActivity] = useState(null);
  const { getImageUrl } = useImage();

  const fetchActivities = async () => {
    try {
      const token = getToken();
      const response = await axios.get(api_url, {
        headers: {
          'Authorization': `${token}`,
        },
      });
      setActivities(response.data);
    } catch (error) {
      console.error('Error fetching activities:', error.message);
    }
  };

  useEffect(() => {
    fetchActivities();
  }, []);

  const handleAddActivity = (newActivity) => {
    setActivities([...activities, newActivity]);
    setShowAddModal(false);
  };

  const handleEditActivity = (updatedActivity) => {
    setActivities(activities.map(activity =>
      activity.activity_id === updatedActivity.activity_id ? updatedActivity : activity
    ));
    setShowEditModal(false);
  };

  const handleDeleteActivity = async (id) => {
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No token found');
      }
  
      const response = await axios.post(
        `${API_URL}/activity/deleteActivity`,
        { activity_id: id }, 
        {
          headers: {
            'Authorization': `${token}`, 
            'Content-Type': 'application/json',
          },
        }
      );
  
      if (response.status === 200) {
        setActivities(activities.filter(activity => activity.activity_id !== id));
      } else {
        throw new Error('Failed to delete activity');
      }
    } catch (error) {
      console.error('Error deleting activity:', error.response ? error.response.data : error.message);
    }
  };
  

  return (
    <div className="p-6">
      <div className="mb-6 flex justify-end">
        <button 
          onClick={() => setShowAddModal(true)} 
          className="flex items-center gap-2 bg-green-800 text-yellow-300 px-4 py-2 rounded-md hover:bg-green-700 transition-colors duration-200"
        >
          <FaPlus size={14} className="text-yellow-300" />
          Add Activity
        </button>
      </div>

      {activities.length === 0 ? (
        <p className="text-gray-500 text-center py-4">No activities added.</p>
      ) : (
        <ul className="space-y-4">
          {activities.map(activity => (
            <li key={activity.activity_id} 
                className="flex items-center space-x-4 border-b border-gray-100 py-4 last:border-0">
              {activity.activity_image ? (
                <img
                  src={getImageUrl(activity.activity_image)}
                  alt={activity.activity_name}
                  className="w-28 h-28 object-cover rounded-lg"
                />
              ) : (
                <div className="w-28 h-28 bg-gray-100 flex items-center justify-center rounded-lg">
                  <span className="text-gray-400">No Image</span>
                </div>
              )}
              <div className="flex-grow">
                <div className="font-semibold text-gray-800">{activity.activity_name}</div>
                <div className="text-sm text-gray-500">Order: {activity.activity_order}</div>
              </div>
              <div className="flex space-x-3">
                <button
                  onClick={() => {
                    setCurrentActivity(activity);
                    setShowEditModal(true);
                  }}
                  className="text-green-700 hover:text-green-800 transition-colors duration-200"
                >
                  <FaEdit size={16} />
                </button>
                <button
                  onClick={() => handleDeleteActivity(activity.activity_id)}
                  className="text-yellow-300 hover:text-red-500 transition-colors duration-200"
                >
                  <FaTrashAlt size={16} />
                </button>
              </div>
            </li>
          ))}
        </ul>
      )}

      {showAddModal && (
        <AddActivity
          onClose={() => setShowAddModal(false)}
          onAddActivity={handleAddActivity}
        />
      )}

      {showEditModal && currentActivity && (
        <EditActivity
          activity={currentActivity}
          onClose={() => setShowEditModal(false)}
          onUpdateActivity={handleEditActivity}
        />
      )}
    </div>
  );
}

export default Activities;

import React, { useState } from 'react';
import ArticleList from '../components/ArticleList';
import ArticleForm from '../components/ArticleForm';

const ArticlesPage = () => {
  const [selectedArticle, setSelectedArticle] = useState(null);
  const [isFormVisible, setFormVisible] = useState(false);

  const handleAddNew = () => {
    setSelectedArticle(null);
    setFormVisible(true);
  };

  const handleEdit = (article) => {
    setSelectedArticle(article);
    setFormVisible(true);
  };

  const handleFormSave = () => {
    setFormVisible(false);
    // Optionally, refresh the article list here if needed
  };

  const handleFormCancel = () => {
    setFormVisible(false);
  };

  return (
    <div className="p-6">
      <h1 className="text-2xl font-semibold mb-6">Articles</h1>
      {isFormVisible ? (
        <ArticleForm
          article={selectedArticle}
          onSave={handleFormSave}
          onCancel={handleFormCancel}
        />
      ) : (
        <>
          <button
            onClick={handleAddNew}
            className="mb-4 px-4 py-2 bg-green-700 text-white rounded-md hover:bg-green-600"
          >
            Add New Article
          </button>
          <ArticleList onEdit={handleEdit} />
        </>
      )}
    </div>
  );
};

export default ArticlesPage; 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MultiSelectDropdown from './Multiselect'
import Loader from './Loader';
import { useImage } from './ImageContext';
import { FaPlus, FaTimes } from 'react-icons/fa';

const api_url = `${API_URL}/package/updatePackage`;
const TourEditForm = ({onSave, onClose }) => {
  const navigate = useNavigate();
  const { packageId } = useParams();
  const { getImageUrl } = useImage();
  const [loading, setLoading] = useState(true);
  const [successMessage, setSuccessMessage] = useState('');
  const [activities, setActivities] = useState([]);
  const [destinations, setDestinations] = useState([]);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    package_title: '',
    package_summary: '',
    package_image: '',
    package_images: [],
    package_location: '',
    destination_id: '',
    activities: [],
    tour_type: [],
    package_days: '',
    package_nights: '',
    package_price: '',
    package_itinerary: [{ title: '', content: '' }],
    package_inclusions: [],
    package_exclusions: [],
    package_requirements: [],
    package_minage: '',
    package_maxpeople: '',
    free_cancelation: false,
    best_price_guarantee: false,
    is_popular: false,
  });
  const tourTypes = [
    { value: 'Solo', label: 'Solo' },
    { value: 'Family Tour', label: 'Family Tour' },
    { value: 'Group Tour', label: 'Group Tour' },
  ];

  useEffect(() => {
    console.log('Fetched packageId:', packageId); 
    const fetchTourData = async () => {
      try {
        const token = getToken();
        const { data } = await axios.get(`${API_URL}/package/getPackagesById/${packageId}`, {
          headers: {
            Authorization: `${token}`,
          },
        });
        setFormData({
          ...data,
          package_inclusions: data.package_inclusions || [],
          package_exclusions: data.package_exclusions || [],
          package_images: data.package_images || [],
        });
      } catch (error) {
        setError('Failed to load tour data. Please try again.');
      } finally {
        setLoading(false);
      }
    };

    fetchTourData();
  }, [packageId]);

  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;

    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const uploadedFileName = await handleImageUpload(file);
          if (uploadedFileName) {
            setFormData(prevFormData => ({
              ...prevFormData,
              [name]: uploadedFileName,
            }));
          }
        } catch (error) {
          setError('Failed to upload image. Please try again.');
        }
      }
    } else if (name.startsWith('itinerary[')) {
      const index = parseInt(name.match(/\d+/)[0], 10);
      const field = name.includes('title') ? 'title' : 'content';
      setFormData(prevFormData => {
        const updatedItinerary = [...prevFormData.package_itinerary];
        updatedItinerary[index] = { ...updatedItinerary[index], [field]: value };
        return { ...prevFormData, package_itinerary: updatedItinerary };
      });
    } else if (name.endsWith('[]')) {
      const fieldName = name.replace('[]', '');
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: value.split(',').map(item => item.trim()),
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      }));
    }
  };

  const handleFileChange = async (files) => {
    const fileArray = Array.from(files);
    const uploadedFileNames = [];
    try {
      const uploadPromises = fileArray.map(file => handleImageUpload(file));
      const results = await Promise.all(uploadPromises);
      results.forEach(result => {
        if (result) uploadedFileNames.push(result);
      });
      setFormData(prevFormData => {
        const updatedImages = [...prevFormData.package_images, ...uploadedFileNames];
        return {
          ...prevFormData,
          package_images: updatedImages,
        };
      });
    } catch (error) {
      setError('Failed to upload images. Please try again.');
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);
    try {
      const token = getToken();
      const { data, status } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });
      console.log('Upload response:', data);
      if (status === 200) return data.file_name;
      throw new Error('Image upload failed');
    } catch (error) {
      console.error('Error during image upload:', error);
      setError('Failed to upload image. Please try again.');
    }
  };

  const addItineraryItem = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_itinerary: [...prevFormData.package_itinerary, { title: '', content: '' }]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');

    try {
      const token = getToken();
      if (!token) throw new Error('No authorization token found.');

      const formattedData = {
        ...formData,
        activities: JSON.stringify(formData.activities),
        best_price_guarantee: formData.best_price_guarantee,
        destination_id: formData.destination_id,
        free_cancelation: formData.free_cancelation,
        is_popular: formData.is_popular,
        package_days: Number(formData.package_days),
        package_exclusions: JSON.stringify(formData.package_exclusions),
        package_image: formData.package_image,
        package_images: JSON.stringify(formData.package_images),
        package_inclusions: JSON.stringify(formData.package_inclusions),
        package_itinerary: JSON.stringify(formData.package_itinerary),
        package_location: formData.package_location,
        package_maxpeople: Number(formData.package_maxpeople),
        package_minage: Number(formData.package_minage),
        package_nights: Number(formData.package_nights),
        package_price: Number(formData.package_price),
        package_requirements: JSON.stringify(formData.package_requirements),
        package_summary: formData.package_summary,
        tour_type: JSON.stringify(formData.tour_type),
      };

      const response = await axios.post(api_url, formattedData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        setSuccessMessage('Package updated successfully!');
        if (onSave) {
          onSave(formattedData);
        }
        navigate('/tours'); 
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    }  catch (error) {
      if (!error.response || error.response.status !== 200) {
        setError(error.response?.data?.message || 'Failed to update package. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${API_URL}/activity/getAllActivities`,{
          headers: {
            'Authorization': `${token}`,
          },
        });
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching activities:', error.message);
      }
    };

    fetchActivities();
  }, []);

  const handleActivityChange = (selectedValues) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      activities: selectedValues
    }));
  };
  const fetchDestinations = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/destination/getAllDestinations`,{
        headers: {
          'Authorization': `${token}`,
        },
      });
      setDestinations(response.data); 
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };

  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleTourTypeChange = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      tour_type: selectedOptions
    }));
  };

  const removeImage = (imageName) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_images: prevFormData.package_images.filter(img => img !== imageName),
    }));
  };

  const addInclusion = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_inclusions: [...prevFormData.package_inclusions, '']
    }));
  };

  const handleInclusionChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedInclusions = [...prevFormData.package_inclusions];
      updatedInclusions[index] = value;
      return { ...prevFormData, package_inclusions: updatedInclusions };
    });
  };

  const removeInclusion = (index) => {
    setFormData(prevFormData => {
      const updatedInclusions = prevFormData.package_inclusions.filter((_, i) => i !== index);
      return { ...prevFormData, package_inclusions: updatedInclusions };
    });
  };

  const addExclusion = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_exclusions: [...prevFormData.package_exclusions, '']
    }));
  };

  const handleExclusionChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedExclusions = [...prevFormData.package_exclusions];
      updatedExclusions[index] = value;
      return { ...prevFormData, package_exclusions: updatedExclusions };
    });
  };

  const removeExclusion = (index) => {
    setFormData(prevFormData => {
      const updatedExclusions = prevFormData.package_exclusions.filter((_, i) => i !== index);
      return { ...prevFormData, package_exclusions: updatedExclusions };
    });
  };

  const addRequirement = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_requirements: [...prevFormData.package_requirements, '']
    }));
  };

  const handleRequirementChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedRequirements = [...prevFormData.package_requirements];
      updatedRequirements[index] = value;
      return { ...prevFormData, package_requirements: updatedRequirements };
    });
  };

  const removeRequirement = (index) => {
    setFormData(prevFormData => {
      const updatedRequirements = prevFormData.package_requirements.filter((_, i) => i !== index);
      return { ...prevFormData, package_requirements: updatedRequirements };
    });
  };

  const removeItineraryItem = (index) => {
    setFormData(prevFormData => {
      const updatedItinerary = prevFormData.package_itinerary.filter((_, i) => i !== index);
      return { ...prevFormData, package_itinerary: updatedItinerary };
    });
  };

  return (
    <div className="max-w-5xl  mx-auto p-6 bg-white rounded-md" style={{ maxHeight: 'calc(100vh - 100px)' }}>
        {loading ? (
          <Loader />
        ) : (
          <form onSubmit={handleSubmit}>
              <h2 className="text-xl font-semibold text-green-800 mb-4">Edit Package Details</h2>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {/* Package Title */}
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">Package Title</label>
                <input
                  type="text"
                  name="package_title"
                  value={formData.package_title}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Package Summary */}
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700 mb-2">Package Summary</label>
                <textarea
                  name="package_summary"
                  value={formData.package_summary}
                  onChange={handleChange}
                  rows="6"
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-3 min-h-[200px] resize-y"
                  style={{ lineHeight: '1.5' }}
                  placeholder="Enter package summary..."
                />
              </div>

              {/* Package Image */}
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">Package Image</label>
                <input
                  type="file"
                  name="package_image"
                  onChange={handleChange}
                  className="mt-1 block w-full"
                />
              </div>

              {/* Additional Images */}
              <div className="col-span-2">
                <label className="block text-sm font-medium text-gray-700">Additional Images</label>
                <div className="flex flex-wrap gap-2">
                  {formData.package_images.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={getImageUrl(image)}
                        alt={`Additional  ${index + 1}`}
                        className="w-32 h-32 object-cover rounded-md"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(image)}
                        className="absolute top-0 right-0 text-red-500"
                      >
                        <FontAwesomeIcon icon={faTimes} />
                      </button>
                    </div>
                  ))}
                  <input
                    type="file"
                    multiple
                    onChange={(e) => handleFileChange(e.target.files)}
                    className="mt-1 block w-full"
                  />
                </div>
              </div>

              {/* Location */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Package Attraction</label>
                <input
                  type="text"
                  name="package_location"
                  value={formData.package_location}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>

              {/* Destination ID */}
              <div>
              <label className="block text-sm font-medium text-gray-700">Destination</label>
              <select
    name="destination_id"
    value={formData.destination_id}
    onChange={handleChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
  >
    <option value="">Select a Destination</option>
    {destinations.map((destination) => (
      <option key={destination.destination_id} value={destination.destination_id}>
        {destination.destination_name}
      </option>
    ))}
  </select>
 </div>
              <div>
              <label className="block text-sm font-medium text-gray-700">Package Min Age</label>
              <input
                type="number"
                name="package_minage"
                value={formData.package_minage}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter minimum age"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Max People</label>
              <input
                type="number"
                name="package_maxpeople"
                value={formData.package_maxpeople}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter maximum number of people"
              />
            </div>
            <div>
      <MultiSelectDropdown
        options={activities.map(activity => ({
          value: activity.activity_name,
          label: activity.activity_name
        }))}
        selectedOptions={formData.activities}
        onChange={handleActivityChange}
        label="Select Activities"
      />
    </div>
    <div>
              <label className="block text-sm font-medium text-gray-700">Tour Type</label>
              <MultiSelectDropdown
          options={tourTypes}
          selectedOptions={formData.tour_type}
          onChange={handleTourTypeChange}        />
            </div>

              {/* Days */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Days</label>
                <input
                  type="number"
                  name="days"
                  value={formData.package_days}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              <div>
              <label className="block text-sm font-medium text-gray-700">Package Nights</label>
              <input
                type="number"
                name="package_nights"
                value={formData.package_nights}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of nights"
              />
            </div>

              {/* Price */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Price</label>
                <input
                  type="number"
                  name="package_price"
                  value={formData.package_price}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              {/* Rating */}
              <div>
                <label className="block text-sm font-medium text-gray-700">Rating</label>
                <input
                  type="number"
                  name="rating"
                  value={formData.rating}
                  onChange={handleChange}
                  className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
                />
              </div>
              {/* {Itinerary} */}
              <div className="col-span-2">
  <div className="flex items-center justify-between mb-2">
    <label className="block text-sm font-medium text-gray-700">Itinerary</label>
    <button
      type="button"
      onClick={addItineraryItem}
      className="text-green-600 hover:text-green-700 p-1"
      title="Add Itinerary Item"
    >
      <FaPlus size={14} />
    </button>
  </div>
  {formData.package_itinerary.map((item, index) => (
    <div key={index} className="space-y-2 mb-4">
      <div className="flex items-center">
        <input
          type="text"
          name={`itinerary[${index}].title`}
          value={item.title}
          onChange={handleChange}
          className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
          placeholder="Day title"
        />
        <button
          type="button"
          onClick={() => removeItineraryItem(index)}
          className="ml-2 text-red-500 hover:text-red-600"
        >
          <FaTimes size={14} />
        </button>
      </div>
      <textarea
        name={`itinerary[${index}].content`}
        value={item.content}
        onChange={handleChange}
        className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
        placeholder="Day description"
        rows="3"
      />
    </div>
  ))}
</div>

              {/* Requirements */}
              <div className="col-span-2">
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-sm font-medium text-gray-700">Requirements</label>
                  <button
                    type="button"
                    onClick={addRequirement}
                    className="text-green-600 hover:text-green-700 p-1"
                    title="Add Requirement"
                  >
                    <FaPlus size={14} />
                  </button>
                </div>
                <div className="space-y-2">
                  {formData.package_requirements.map((requirement, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        value={requirement}
                        onChange={(e) => handleRequirementChange(index, e.target.value)}
                        className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        placeholder="Enter requirement"
                      />
                      <button
                        type="button"
                        onClick={() => removeRequirement(index)}
                        className="ml-2 text-red-500 hover:text-red-600"
                      >
                        <FaTimes size={14} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Special */}
              <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-4">
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="free_cancelation"
                    checked={formData.free_cancelation}
                    onChange={handleChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label className="ml-2 block text-sm font-medium text-gray-700">Free Cancelation</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="best_price_guarantee"
                    checked={formData.best_price_guarantee}
                    onChange={handleChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label className="ml-2 block text-sm font-medium text-gray-700">Best Price Guarantee</label>
                </div>
                <div className="flex items-center">
                  <input
                    type="checkbox"
                    name="is_popular"
                    checked={formData.is_popular}
                    onChange={handleChange}
                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                  />
                  <label className="ml-2 block text-sm font-medium text-gray-700">Mark as Popular</label>
                </div>
              </div>

              {/* Inclusions */}
              <div className="col-span-2">
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-sm font-medium text-gray-700">Inclusions</label>
                  <button
                    type="button"
                    onClick={addInclusion}
                    className="text-green-600 hover:text-green-700 p-1"
                    title="Add Inclusion"
                  >
                    <FaPlus size={14} />
                  </button>
                </div>
                <div className="space-y-2">
                  {formData.package_inclusions.map((inclusion, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        value={inclusion}
                        onChange={(e) => handleInclusionChange(index, e.target.value)}
                        className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        placeholder="Enter inclusion"
                      />
                      <button
                        type="button"
                        onClick={() => removeInclusion(index)}
                        className="ml-2 text-red-500 hover:text-red-600"
                      >
                        <FaTimes size={14} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Exclusions */}
              <div className="col-span-2">
                <div className="flex items-center justify-between mb-2">
                  <label className="block text-sm font-medium text-gray-700">Exclusions</label>
                  <button
                    type="button"
                    onClick={addExclusion}
                    className="text-green-600 hover:text-green-700 p-1"
                    title="Add Exclusion"
                  >
                    <FaPlus size={14} />
                  </button>
                </div>
                <div className="space-y-2">
                  {formData.package_exclusions.map((exclusion, index) => (
                    <div key={index} className="flex items-center">
                      <input
                        type="text"
                        value={exclusion}
                        onChange={(e) => handleExclusionChange(index, e.target.value)}
                        className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                        placeholder="Enter exclusion"
                      />
                      <button
                        type="button"
                        onClick={() => removeExclusion(index)}
                        className="ml-2 text-red-500 hover:text-red-600"
                      >
                        <FaTimes size={14} />
                      </button>
                    </div>
                  ))}
                </div>
              </div>

              {/* Buttons */}
              <div className="col-span-2 flex justify-between mt-4">
                <button
                  type="button"
                  onClick={onClose}
                  className="py-2 px-4 bg-gray-500 text-white rounded-md hover:bg-gray-600"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={loading}
                  className="py-2 px-4 bg-green-500 text-white rounded-md hover:bg-green-600"
                >
                  {loading ? 'Saving...' : 'Save Changes'}
                </button>
              </div>
            </div>

            {/* Error and Success Messages */}
            {error && (
              <div className="text-red-600 mt-4">{error}</div>
            )}
            {successMessage && (
              <div className="text-green-600 mt-4">{successMessage}</div>
            )}
          </form>
        )}
      </div>
  );
};

export default TourEditForm;

import React, { useEffect, useState } from "react";
import axios from "axios";
import { API_URL } from "../variables";
import { getToken } from '../authService';
import Loader from "./Loader";
import { FaEdit, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa';
import { useImage } from './ImageContext';

const ArticleList = ({ onEdit, onDelete }) => {
  const [articles, setArticles] = useState([]);
  const [loading, setLoading] = useState(true);
  const { getImageUrl } = useImage();
  const [expandedArticle, setExpandedArticle] = useState(null);

  useEffect(() => {
    fetchArticles();
  }, []);

  const fetchArticles = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/article/getAllArticles`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setArticles(response.data);
    } catch (error) {
      console.error("Error fetching articles:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (articleId) => {
    if (window.confirm('Are you sure you want to delete this article?')) {
      try {
        const token = getToken();
        await axios.post(`${API_URL}/article/delete/${articleId}`, {}, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        fetchArticles();
      } catch (error) {
        console.error("Error deleting article:", error);
      }
    }
  };

  const toggleContent = (articleId) => {
    setExpandedArticle(expandedArticle === articleId ? null : articleId);
  };

  return (
    <div className="space-y-4">
      {loading ? (
        <Loader />
      ) : (
        <div className="bg-white rounded-lg shadow overflow-hidden">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              <tr className="bg-gray-50">
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Image</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Title</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Author</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Date</th>
                <th className="px-4 py-2 text-left text-xs font-medium text-gray-500 uppercase">Actions</th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200">
              {articles.map((article) => (
                <React.Fragment key={article.article_id}>
                  <tr className="hover:bg-gray-50">
                    <td className="px-4 py-2">
                      <img 
                        src={getImageUrl(article.article_image)} 
                        alt={article.article_title}
                        className="h-10 w-10 object-cover rounded"
                      />
                    </td>
                    <td className="px-4 py-2">
                      <button 
                        onClick={() => toggleContent(article.article_id)}
                        className="flex items-center space-x-2 text-left"
                      >
                        <div className="text-sm text-gray-900 truncate max-w-xs hover:text-blue-600">
                          {article.article_title}
                        </div>
                        {expandedArticle === article.article_id ? 
                          <FaChevronUp className="text-gray-500" size={12} /> : 
                          <FaChevronDown className="text-gray-500" size={12} />
                        }
                      </button>
                    </td>
                    <td className="px-4 py-2 text-sm">{article.article_author}</td>
                    <td className="px-4 py-2 text-sm">
                      {new Date(article.createdAt).toLocaleDateString()}
                    </td>
                    <td className="px-4 py-2">
                      <div className="flex space-x-2">
                        <button
                          onClick={() => onEdit({
                            id: article.article_id,
                            title: article.article_title,
                            content: article.article_content,
                            image: article.article_image,
                            author: article.article_author
                          })}
                          className="text-blue-600 hover:text-blue-900"
                        >
                          <FaEdit size={14} />
                        </button>
                        <button
                          onClick={() => handleDelete(article.article_id)}
                          className="text-red-600 hover:text-red-900"
                        >
                          <FaTrash size={14} />
                        </button>
                      </div>
                    </td>
                  </tr>
                  {expandedArticle === article.article_id && (
                    <tr>
                      <td colSpan="5" className="px-4 py-3 bg-gray-50">
                        <div className="text-sm text-gray-700"
                             dangerouslySetInnerHTML={{ __html: article.article_content }} 
                        />
                      </td>
                    </tr>
                  )}
                </React.Fragment>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ArticleList; 
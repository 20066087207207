import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import { getToken } from '../authService';
import { useImage } from './ImageContext';

const ArticleForm = ({ article, onSave, onCancel }) => {
  const [formData, setFormData] = useState({
    title: '',
    content: '',
    image: '',
    author: 'Emma'
  });
  const [error, setError] = useState('');
  const { getImageUrl } = useImage();

  useEffect(() => {
    if (article) {
      setFormData({
        title: article.title,
        content: article.content,
        image: article.image,
        author: article.author || 'Emma'
      });
    }
  }, [article]);

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const token = getToken();
      const { data, status } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (status === 200) {
        return data.file_name;
      } else {
        throw new Error('Image upload failed');
      }
    } catch (error) {
      console.error('Image upload error:', error);
      setError('Failed to upload image. Please try again.');
    }
  };

  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;
  
    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const uploadedFileName = await handleImageUpload(file);
          if (uploadedFileName) {
            setFormData(prevFormData => ({
              ...prevFormData,
              image: uploadedFileName,
            }));
          }
        } catch (error) {
          console.error('File upload failed:', error);
          setError('Failed to upload image. Please try again.');
        }
      }
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = getToken();
      const formDataToSend = new FormData();
      formDataToSend.append('article_title', formData.title);
      formDataToSend.append('article_content', formData.content);
      formDataToSend.append('article_author', formData.author);
      formDataToSend.append('article_image', formData.image);

      const url = article
        ? `${API_URL}/article/updateArticle/${article.id}`
        : `${API_URL}/article/createArticle`;

      const response = await axios.post(url, formDataToSend, {
        headers: {
          'Authorization': `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (response.status === 200 || response.status === 201) {
        onSave();
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Error saving article');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Title</label>
        <input
          type="text"
          name="title"
          value={formData.title}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Author</label>
        <input
          type="text"
          name="author"
          value={formData.author}
          onChange={handleChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Content</label>
        <textarea
          name="content"
          value={formData.content}
          onChange={handleChange}
          rows={6}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-green-500 focus:ring-green-500"
          required
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-gray-700">Image</label>
        <input
          type="file"
          onChange={handleChange}
          className="mt-1 block w-full"
          accept="image/*"
          required={!article}
        />
        {formData.image && (
          <img 
            src={getImageUrl(formData.image)} 
            alt="Article preview" 
            className="mt-2 h-20 w-auto"
          />
        )}
      </div>

      {error && <div className="text-red-600">{error}</div>}

      <div className="flex justify-end space-x-2">
        <button
          type="button"
          onClick={onCancel}
          className="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-green-600 hover:bg-green-700"
        >
          {article ? 'Update' : 'Create'} Article
        </button>
      </div>
    </form>
  );
};

export default ArticleForm; 
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../variables'; 
import { getToken } from '../authService'; 
import { Link } from 'react-router-dom';
import Loader from '../components/Loader'; 
import { FaSuitcase, FaCalendarAlt, FaPhoneAlt, FaMapMarkerAlt } from 'react-icons/fa'; 
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

function Dashboard() {
  const [pendingBookingsCount, setPendingBookingsCount] = useState(0);
  const [pendingReservationCount, setPendingReservationCount] = useState(0);
  const [totalTours, setTotalTours] = useState(0);
  const [contactRequests, setContactRequests] = useState(0);
  const [monthlyBookings, setMonthlyBookings] = useState([]);
  const [topBookedTours, setTopBookedTours] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const token = getToken(); 

        const headers = {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        };

        // Fetch packages
        const { data: packages } = await axios.get(`${API_URL}/package/getAllPackages`, { headers });
        setTotalTours(packages.length);

        // Fetch custom reservations
        const { data: customReservations } = await axios.get(`${API_URL}/custom_reservations/getAllReservations`, { headers });
        setPendingBookingsCount(customReservations.length);

        // Fetch standard reservations
        const { data: reservations } = await axios.get(`${API_URL}/reservations/getAllReservations`, { headers });
        setPendingReservationCount(reservations.length);

        // Fetch contact requests
        const { data: contacts } = await axios.get(`${API_URL}/contacts/getAllContacts`, { headers });
        setContactRequests(contacts.length);

        // Process monthly bookings
        const processMonthlyBookings = () => {
          const currentDate = new Date();
          const currentYear = currentDate.getFullYear();
          const currentMonth = currentDate.getMonth(); // 0-based (0-11)
          const months = [
            'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 
            'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
          ];

          // Only process data up to current month
          const bookingsData = months.slice(0, currentMonth + 1).map((month, index) => {
            const customBookings = customReservations.filter(reservation => {
              const reservationDate = new Date(reservation.createdAt);
              return reservationDate.getFullYear() === currentYear &&
                     reservationDate.getMonth() === index;
            }).length;

            const standardBookings = reservations.filter(reservation => {
              const reservationDate = new Date(reservation.createdAt);
              return reservationDate.getFullYear() === currentYear &&
                     reservationDate.getMonth() === index;
            }).length;

            return {
              month,
              customBookings,
              standardBookings,
              totalBookings: customBookings + standardBookings
            };
          });

          // Calculate cumulative bookings
          const cumulativeBookingsData = bookingsData.map((monthData, index) => ({
            ...monthData,
            cumulativeCustomBookings: bookingsData
              .slice(0, index + 1)
              .reduce((sum, data) => sum + data.customBookings, 0),
            cumulativeStandardBookings: bookingsData
              .slice(0, index + 1)
              .reduce((sum, data) => sum + data.standardBookings, 0)
          }));

          // Add December with null values for display only
          const finalData = [
            ...cumulativeBookingsData,
            {
              month: 'Dec',
              customBookings: null,
              standardBookings: null,
              totalBookings: null,
              cumulativeCustomBookings: null,
              cumulativeStandardBookings: null
            }
          ];

          setMonthlyBookings(finalData);
        };

        const processTopBookedTours = () => {
          const tourBookings = {};

          reservations.forEach(reservation => {
            const packageTitle = reservation.package?.package_title;
            if (packageTitle) {
              tourBookings[packageTitle] = (tourBookings[packageTitle] || 0) + 1;
            }
          });

          customReservations.forEach(reservation => {
            const packageTitle = reservation.package?.package_title;
            if (packageTitle) {
              tourBookings[packageTitle] = (tourBookings[packageTitle] || 0) + 1;
            }
          });

          const sortedTours = Object.entries(tourBookings)
            .map(([name, bookings]) => ({ name, bookings }))
            .sort((a, b) => b.bookings - a.bookings)
            .slice(0, 5);

          setTopBookedTours(sortedTours);
        };
        
        processMonthlyBookings();
        processTopBookedTours();

        setLoading(false); 
      } catch (err) {
        setError('Failed to load dashboard data.');
        console.error(err);
        setLoading(false); 
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) {
    return <Loader />; 
  }

  if (error) {
    return <div className="text-red-500 p-4">{error}</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-6 lg:px-8 py-6 bg-gray-100 min-h-screen">
      <div className="bg-white rounded-lg mb-6 shadow-md p-6 border border-gray-200">
        <h1 className="text-2xl font-semibold text-gray-800">Dashboard Overview</h1>
        <p className="text-sm text-gray-600 mt-1">Welcome back! Here's what's happening today.</p>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-8">
        {[
          { 
            icon: <FaMapMarkerAlt className="w-6 h-6" />, 
            label: 'Total Tours', 
            value: totalTours, 
            link: '/tours',
            bgColor: 'bg-blue-500',
            textColor: 'text-blue-500'
          },
          { 
            icon: <FaSuitcase className="w-6 h-6" />, 
            label: 'Custom Reservations', 
            value: pendingBookingsCount, 
            link: '/bookings/custom',
            bgColor: 'bg-green-500',
            textColor: 'text-green-500'
          },
          { 
            icon: <FaCalendarAlt className="w-6 h-6" />, 
            label: 'Reservations', 
            value: pendingReservationCount, 
            link: '/bookings/reserved',
            bgColor: 'bg-purple-500',
            textColor: 'text-purple-500'
          },
          { 
            icon: <FaPhoneAlt className="w-6 h-6" />, 
            label: 'Contact Requests', 
            value: contactRequests, 
            link: '/contact',
            bgColor: 'bg-orange-500',
            textColor: 'text-orange-500'
          }
        ].map((card, index) => (
          <Link 
            key={index} 
            to={card.link} 
            className="bg-white rounded-lg p-6 hover:shadow-lg transition-all duration-200 border border-gray-200"
          >
            <div className="flex items-center space-x-4">
              <div className={`${card.bgColor} bg-opacity-10 p-4 rounded-lg`}>
                <div className={card.textColor}>
                  {card.icon}
                </div>
              </div>
              <div>
                <p className="text-sm font-medium text-gray-500">{card.label}</p>
                <p className="text-2xl font-bold text-gray-800 mt-1">{card.value}</p>
              </div>
            </div>
          </Link>
        ))}
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-3 gap-6 mb-8">
        <div className="lg:col-span-2 bg-white rounded-lg p-6 shadow-md border border-gray-200">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-gray-800">Bookings Growth</h2>
            <div className="text-sm text-gray-500">This Year</div>
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={monthlyBookings}>
              <CartesianGrid stroke="#e5e7eb" strokeDasharray="0" />
              <XAxis 
                dataKey="month" 
                tick={{ fontSize: 12 }}
                axisLine={{ stroke: '#e5e7eb' }}
              />
              <YAxis 
                tick={{ fontSize: 12 }}
                axisLine={{ stroke: '#e5e7eb' }}
              />
              <Tooltip 
                contentStyle={{ 
                  backgroundColor: '#fff',
                  border: 'none',
                  borderRadius: '8px',
                  boxShadow: '0 2px 8px rgba(0,0,0,0.15)',
                  padding: '12px'
                }}
              />
              <Legend />
              <Line 
                type="monotone" 
                dataKey="cumulativeCustomBookings" 
                stroke="#6366f1"  
                strokeWidth={3}
                dot={{ r: 4, strokeWidth: 2 }}
                name="Custom Bookings"
                connectNulls={false}
              />
              <Line 
                type="monotone" 
                dataKey="cumulativeStandardBookings" 
                stroke="#10b981"  
                strokeWidth={3}
                dot={{ r: 4, strokeWidth: 2 }}
                name="Standard Bookings"
                connectNulls={false}
              />
            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white rounded-lg p-6 shadow-md border border-gray-200">
          <div className="flex items-center justify-between mb-6">
            <h2 className="text-lg font-semibold text-gray-800">Top Tours</h2>
            <div className="text-sm text-gray-500">By Bookings</div>
          </div>
          {topBookedTours.length > 0 ? (
            <div className="space-y-4">
              {topBookedTours.map((tour, index) => (
                <div key={index} className="flex items-center justify-between p-3 rounded-lg hover:bg-gray-50">
                  <div className="flex items-center space-x-3">
                    <span className="text-sm font-medium text-gray-600 w-5">{index + 1}</span>
                    <span className="text-sm font-medium text-gray-800">{tour.name}</span>
                  </div>
                  <span className="text-sm font-semibold text-gray-700 bg-gray-100 px-3 py-1 rounded-full">
                    {tour.bookings}
                  </span>
                </div>
              ))}
            </div>
          ) : (
            <div className="text-center py-8">
              <p className="text-sm text-gray-500">No bookings data available</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
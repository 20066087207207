import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_URL } from '../variables';
import toast, { Toaster } from "react-hot-toast";
import { getToken } from '../authService';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import MultiSelectDropdown from './Multiselect';
import { useImage } from './ImageContext';
import { FaTimes, FaPlus } from 'react-icons/fa';

const api_url = `${API_URL}/package/createPackage`;

const TourForm = ({ onSave, onClose}) => {
  const [activities, setActivities] = useState([]);
  const [step, setStep] = useState(1);
  const [loading, setLoading] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');
  const [destinations, setDestinations] = useState([]);
  const [formData, setFormData] = useState({
    package_title: '',
    package_summary: '',
    package_image: '',
    package_images: [],
    package_location: '',
    destination_id: '',
    activities: [],
    tour_type: [],
    package_days: '',
    package_nights: '',
    package_price: '',
    package_itinerary: [{ title: '', content: '' }],
    package_inclusions: [],
    package_exclusions: [],
    package_requirements: [],
    package_minage: '',
    package_maxpeople: '',
    free_cancelation: false,
    best_price_guarantee: false,
    is_popular: false,
  });
  const tourTypes = [
    { value: 'Solo', label: 'Solo' },
    { value: 'Family Tour', label: 'Family Tour' },
    { value: 'Group Tour', label: 'Group Tour' },
  ];
  const { getImageUrl } = useImage();
  const handleChange = async (event) => {
    const { name, value, type, files } = event.target;
  
    if (type === 'file') {
      const file = files[0];
      if (file) {
        try {
          const uploadedFileName = await handleImageUpload(file);
          if (uploadedFileName) {
            setFormData(prevFormData => ({
              ...prevFormData,
              [name]: uploadedFileName,
            }));
          }
        } catch (error) {
          console.error('File upload failed:', error);
          setError('Failed to upload image. Please try again.');
        }
      }
    } else if (name.startsWith('itinerary[')) {
      const index = parseInt(name.match(/\d+/)[0], 10);
      const field = name.includes('title') ? 'title' : 'content';
  
      setFormData(prevFormData => {
        const updatedItinerary = [...prevFormData.package_itinerary];
        updatedItinerary[index] = {
          ...updatedItinerary[index],
          [field]: value,
        };
        return {
          ...prevFormData,
          package_itinerary: updatedItinerary,
        };
      });
    } else if (name.endsWith('[]')) {
      const fieldName = name.replace('[]', '');
      setFormData(prevFormData => ({
        ...prevFormData,
        [fieldName]: value.split(',').map(item => item.trim()),
      }));
    } else {
      setFormData(prevFormData => ({
        ...prevFormData,
        [name]: type === 'checkbox' ? event.target.checked : value,
      }));
    }
  };
  
  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      try {
        const uploadedFileName = await handleImageUpload(file);
        if (uploadedFileName) {
          setFormData(prevFormData => ({
            ...prevFormData,
            package_images: [...prevFormData.package_images, uploadedFileName]
          }));
        }
      } catch (error) {
        console.error('Error uploading file:', error);
        setError('Failed to upload image. Please try again.');
      }
    }
  };

  const handleImageUpload = async (file) => {
    const formData = new FormData();
    formData.append('image', file);

    try {
      const token = getToken();
      const { data, status } = await axios.post(`${API_URL}/upload`, formData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'multipart/form-data',
        },
      });

      if (status === 200) {
        return data.file_name;
      } else {
        throw new Error('Image upload failed');
      }
    } catch (error) {
      console.error('Image upload error:', error);
      setError('Failed to upload image. Please try again.');
    }
  };
  const addItineraryItem = () => {
    setFormData({
      ...formData,
      package_itinerary: [...formData.package_itinerary, { title: '', content: '' }]
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    setSuccessMessage('');
  
    try {
      const token = getToken();
      if (!token) {
        throw new Error('No authorization token found.');
      }
  
      const formattedData = {
        ...formData,
        activities: JSON.stringify(formData.activities),
        best_price_guarantee: formData.best_price_guarantee,
        destination_id: formData.destination_id,
        free_cancelation: formData.free_cancelation,
        is_popular: formData.is_popular,
        package_days: Number(formData.package_days),
        package_exclusions: JSON.stringify(formData.package_exclusions),
        package_image: formData.package_image,
        package_images: JSON.stringify(formData.package_images),
        package_inclusions: JSON.stringify(formData.package_inclusions),
        package_itinerary: JSON.stringify(formData.package_itinerary),
        package_location: formData.package_location,
        package_maxpeople: Number(formData.package_maxpeople),
        package_minage: Number(formData.package_minage),
        package_nights: Number(formData.package_nights),
        package_price: Number(formData.package_price),
        package_requirements: JSON.stringify(formData.package_requirements),
        package_summary: formData.package_summary,
        tour_type: JSON.stringify(formData.tour_type),
      };
  
      const response = await axios.post(api_url, formattedData, {
        headers: {
          Authorization: `${token}`,
          'Content-Type': 'application/json',
        },
      });
  
      if (response.status === 200) {
        toast.success('Package created successfully!');
        onSave(formattedData);
        resetForm();
      } else {
        throw new Error(`Error ${response.status}: ${response.statusText}`);
      }
    } catch (error) {
      console.error('Error:', error);
  
      if (error.response?.status === 400) {
        error.response.data.errors.forEach((err) => {
          toast.error(`${err.msg} (${err.path})`);
        });
      } else if (error.response?.status === 401) {
        toast.error("You're not authorized!");
      } else {
        toast.error(error.message || 'Failed to add package. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };
  
  const resetForm = () => {
    setFormData({
      package_title: '',
      package_summary: '',
      package_image: '',
      package_images: [],
      package_location: '',
      destination_id: '',
      activities: [],
      tour_type: [],
      package_days: '',
      package_nights: '',
      package_price: '',
      package_itinerary: [],
      package_inclusions: [],
      package_exclusions: [],
      package_requirements: [],
      package_minage: '',
      package_maxpeople: '',
      free_cancelation: false,
      best_price_guarantee: false,
      is_popular: false,
    });
  };
  useEffect(() => {
    const fetchActivities = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${API_URL}/activity/getAllActivities`,{
          headers: {
            'Authorization': `${token}`,
          },
        });
        setActivities(response.data);
      } catch (error) {
        console.error('Error fetching activities:', error.message);
      }
    };

    fetchActivities();
  }, []);

  const handleActivityChange = (selectedValues) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      activities: selectedValues
    }));
  };
  const fetchDestinations = async () => {
    try {
      const token = getToken();
      const response = await axios.get(`${API_URL}/destination/getAllDestinations`,{
        headers: {
          'Authorization': `${token}`,
        },
      });
      setDestinations(response.data); 
    } catch (error) {
      console.error("Error fetching destinations:", error);
    }
  };

  // Fetch destinations when the component mounts
  useEffect(() => {
    fetchDestinations();
  }, []);

  const handleTourTypeChange = (selectedOptions) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      tour_type: selectedOptions
    }));
  };


  const nextStep = () => setStep(step + 1);
  const prevStep = () => setStep(step - 1);

  const addInclusion = () => {
    setFormData(prev => ({
      ...prev,
      package_inclusions: ['', ...prev.package_inclusions]
    }));
  };

  const handleInclusionChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedInclusions = [...prevFormData.package_inclusions];
      updatedInclusions[index] = value;
      return { ...prevFormData, package_inclusions: updatedInclusions };
    });
  };

  const removeInclusion = (index) => {
    setFormData(prevFormData => {
      const updatedInclusions = prevFormData.package_inclusions.filter((_, i) => i !== index);
      return { ...prevFormData, package_inclusions: updatedInclusions };
    });
  };

  const addRequirement = () => {
    setFormData(prev => ({
      ...prev,
      package_requirements: ['', ...prev.package_requirements]
    }));
  };

  const handleRequirementChange = (index, value) => {
    setFormData(prevFormData => {
      const updatedRequirements = [...prevFormData.package_requirements];
      updatedRequirements[index] = value;
      return { ...prevFormData, package_requirements: updatedRequirements };
    });
  };

  const removeRequirement = (index) => {
    setFormData(prevFormData => {
      const updatedRequirements = prevFormData.package_requirements.filter((_, i) => i !== index);
      return { ...prevFormData, package_requirements: updatedRequirements };
    });
  };

  const removeImage = (indexToRemove) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      package_images: prevFormData.package_images.filter((_, index) => index !== indexToRemove)
    }));
  };

  const addExclusion = () => {
    setFormData(prev => ({
      ...prev,
      package_exclusions: ['', ...prev.package_exclusions]
    }));
  };

  const removeExclusion = (indexToRemove) => {
    setFormData(prev => ({
      ...prev,
      package_exclusions: prev.package_exclusions.filter((_, index) => index !== indexToRemove)
    }));
  };

  const handleExclusionChange = (index, value) => {
    setFormData(prev => ({
      ...prev,
      package_exclusions: prev.package_exclusions.map((item, i) => 
        i === index ? value : item
      )
    }));
  };

  return (
<div className="relative max-w-5xl mx-auto bg-white  rounded-lg p-4 flex-grow overflow-y-auto space-y-4" style={{ maxHeight: 'calc(100vh - 100px)', width: '100%' }}>
<Toaster position="top-center" reverseOrder={false} />
      <button
        type="button"
        onClick={onClose}
        className="absolute top-4 right-4 text-gray-600 hover:text-gray-900"
      >
        <FontAwesomeIcon icon={faTimes} size="lg" />
      </button>

      <form onSubmit={handleSubmit}>
        {step === 1 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Package Details</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Title</label>
              <input
                type="text"
                name="package_title"
                value={formData.package_title}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Summary</label>
              <textarea
                name="package_summary"
                value={formData.package_summary}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Main Package Image
                </label>
                <div className="flex items-center">
                  <input
                    type="file"
                    name="package_image"
                    onChange={handleChange}
                    accept="image/*"
                    className="hidden"
                    id="mainImage"
                  />
                  <label
                    htmlFor="mainImage"
                    className="cursor-pointer bg-gray-200 text-black py-2 mr-2 px-4 rounded-md hover:bg-gray-300 transition-colors"
                  >
                    Choose Main Image
                  </label>
                  {formData.package_image && (
                    <div className="relative inline-block">
                      <img
                        src={getImageUrl(formData.package_image)}
                        alt="Main package"
                        className="h-20 w-20 object-cover rounded-md"
                      />
                    </div>
                  )}
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-2">
                  Additional Images
                </label>
                <div className="flex flex-wrap gap-4">
                  {formData.package_images.map((image, index) => (
                    <div key={index} className="relative">
                      <img
                        src={getImageUrl(image)}
                        alt={`Package ${index + 1}`}
                        className="h-24 w-24 object-cover rounded-lg border border-gray-200"
                      />
                      <button
                        type="button"
                        onClick={() => removeImage(index)}
                        className="absolute -top-2 -right-2 bg-red-500 text-white rounded-full w-5 h-5 flex items-center justify-center hover:bg-red-600"
                      >
                        <FaTimes size={10} />
                      </button>
                      <div className="absolute bottom-1 right-1 bg-green-600 text-white text-xs px-1.5 py-0.5 rounded-full">
                        {index + 1}/{formData.package_images.length}
                      </div>
                    </div>
                  ))}
                  <div>
                    <input
                      type="file"
                      onChange={handleFileChange}
                      accept="image/*"
                      className="hidden"
                      id="additionalImage"
                    />
                    <label
                      htmlFor="additionalImage"
                      className="h-24 w-24 border-2 border-dashed border-gray-300 rounded-lg flex items-center justify-center cursor-pointer hover:border-gray-400"
                    >
                      <FaPlus className="text-gray-400" size={24} />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex justify-center">
           <h2 class="align-middle text-xs border border-green-800 p-1 bg-green-700 text-white rounded-full">1/4</h2>
          </div>    
            <div className="flex justify-between">
              <button
                type="button"
                onClick={nextStep}
                className="bg-green-700 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 2 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Location and Tour Type</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Location</label>
              <input
                type="text"
                name="package_location"
                value={formData.package_location}
                onChange={handleChange}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Destination</label>
              <select
    name="destination_id"
    value={formData.destination_id}
    onChange={handleChange}
    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm p-2 text-black"
  >
    <option value="">Select a Destination</option>
    {destinations.map((destination) => (
      <option key={destination.destination_id} value={destination.destination_id}>
        {destination.destination_name}
      </option>
    ))}
  </select>
            </div>
            <div>
      <MultiSelectDropdown
        options={activities.map(activity => ({
          value: activity.activity_name,
          label: activity.activity_name
        }))}
        selectedOptions={formData.activities}
        onChange={handleActivityChange}
        label="Select Activities"
      />
    </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Tour Type</label>
              <MultiSelectDropdown
          options={tourTypes}
          selectedOptions={formData.tour_type}
          onChange={handleTourTypeChange}        />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Min Age</label>
              <input
                type="number"
                name="package_minage"
                value={formData.package_minage}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter minimum age"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Max People</label>
              <input
                type="number"
                name="package_maxpeople"
                value={formData.package_maxpeople}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter maximum number of people"
              />
            </div>
            <div class="flex justify-center">
           <h2 class="align-middle text-xs border border-green-800 p-1 bg-green-700 text-white rounded-full">2/4</h2>
          </div>  

            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="bg-green-700 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
          </div>
        )}

        {step === 3 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Pricing and Itinerary</h2>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Days</label>
              <input
                type="number"
                name="package_days"
                value={formData.package_days}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of days"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Nights</label>
              <input
                type="number"
                name="package_nights"
                value={formData.package_nights}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter number of nights"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700">Package Price</label>
              <input
                type="number"
                name="package_price"
                value={formData.package_price}
                onChange={handleChange}
                className="border rounded p-2 w-full"
                placeholder="Enter price"
              />
            </div>
            <div>
  <div className="flex items-center justify-between mb-2">
    <label className="block text-sm font-medium text-gray-700">Itinerary</label>
    <button
      type="button"
      onClick={addItineraryItem}
      className="text-green-600 hover:text-green-700 p-1"
      title="Add Itinerary Item"
    >
      <FaPlus size={14} />
    </button>
  </div>
  <div className="space-y-4">
    {formData.package_itinerary.map((item, index) => (
      <div key={index} className="space-y-2">
        <div className="flex items-center">
          <input
            type="text"
            name={`itinerary[${index}].title`}
            value={item.title}
            onChange={handleChange}
            className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
            placeholder="Day title"
          />
          <button
            type="button"
            onClick={() => {
              const newItinerary = formData.package_itinerary.filter((_, i) => i !== index);
              setFormData(prev => ({
                ...prev,
                package_itinerary: newItinerary
              }));
            }}
            className="ml-2 text-red-500 hover:text-red-600"
          >
            <FaTimes size={14} />
          </button>
        </div>
        <textarea
          name={`itinerary[${index}].content`}
          value={item.content}
          onChange={handleChange}
          className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
          placeholder="Day description"
          rows="3"
        />
      </div>
    ))}
  </div>
</div>
<div class="flex justify-center">
           <h2 class="align-middle text-xs border border-green-800 p-1 bg-green-700 text-white rounded-full">3/4</h2>
          </div>  

            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
              >
                Previous
              </button>
              <button
                type="button"
                onClick={nextStep}
                className="bg-green-700 text-white py-2 px-4 rounded"
              >
                Next
              </button>
            </div>
            </div>
        )}

        {step === 4 && (
          <div className="space-y-6">
            <h2 className="text-xl font-semibold text-gray-700">Inclusions, Exclusions, and Other Details</h2>
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">Inclusions</label>
                <button
                  type="button"
                  onClick={addInclusion}
                  className="text-green-600 hover:text-green-700 p-1"
                  title="Add Inclusion"
                >
                  <FaPlus size={14} />
                </button>
              </div>
              <div className="space-y-2">
                {formData.package_inclusions.map((inclusion, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="text"
                      value={inclusion}
                      onChange={(e) => handleInclusionChange(index, e.target.value)}
                      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="Enter inclusion"
                      autoFocus={index === 0}
                    />
                    <button
                      type="button"
                      onClick={() => removeInclusion(index)}
                      className="ml-2 text-red-500 hover:text-red-600"
                    >
                      <FaTimes size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">Exclusions</label>
                <button
                  type="button"
                  onClick={addExclusion}
                  className="text-green-600 hover:text-green-700 p-1"
                  title="Add Exclusion"
                >
                  <FaPlus size={14} />
                </button>
              </div>
              <div className="space-y-2">
                {formData.package_exclusions.map((exclusion, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="text"
                      value={exclusion}
                      onChange={(e) => handleExclusionChange(index, e.target.value)}
                      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="Enter exclusion"
                      autoFocus={index === 0}
                    />
                    <button
                      type="button"
                      onClick={() => removeExclusion(index)}
                      className="ml-2 text-red-500 hover:text-red-600"
                    >
                      <FaTimes size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between mb-2">
                <label className="block text-sm font-medium text-gray-700">Requirements</label>
                <button
                  type="button"
                  onClick={addRequirement}
                  className="text-green-600 hover:text-green-700 p-1"
                  title="Add Requirement"
                >
                  <FaPlus size={14} />
                </button>
              </div>
              <div className="space-y-2">
                {formData.package_requirements.map((requirement, index) => (
                  <div key={index} className="flex items-center">
                    <input
                      type="text"
                      value={requirement}
                      onChange={(e) => handleRequirementChange(index, e.target.value)}
                      className="block w-full border border-gray-300 rounded-md shadow-sm p-2"
                      placeholder="Enter requirement"
                      autoFocus={index === 0}
                    />
                    <button
                      type="button"
                      onClick={() => removeRequirement(index)}
                      className="ml-2 text-red-500 hover:text-red-600"
                    >
                      <FaTimes size={14} />
                    </button>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="free_cancelation"
                checked={formData.free_cancelation}
                onChange={handleChange}
                className="h-4 w-4 text-green-700 focus:ring-green-800 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Free Cancelation</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="best_price_guarantee"
                checked={formData.best_price_guarantee}
                onChange={handleChange}
                className="h-4 w-4 text-green-700 focus:ring-green-800 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Best Price Guarantee</label>
            </div>
            <div className="flex items-center">
              <input
                type="checkbox"
                name="is_popular"
                checked={formData.is_popular}
                onChange={handleChange}
                className="h-4 w-4 text-green-700 focus:ring-green-800 border-gray-300 rounded"
              />
              <label className="ml-2 block text-sm font-medium text-gray-700">Mark as Popular</label>
            </div>
            <div class="flex justify-center">
           <h2 class="align-middle text-xs border border-green-800 p-1 bg-green-700 text-white rounded-full">4/4</h2>
          </div>  

            <div className="flex justify-between">
              <button
                type="button"
                onClick={prevStep}
                className="bg-gray-300 text-gray-700 py-2 px-4 rounded"
              >
                Previous
              </button>
              <button
                type="submit"
                className="bg-green-500 text-white py-2 px-4 rounded"
                disabled={loading}
              >
                {loading ? 'Submitting...' : 'Submit'}
              </button>
            </div>
          </div>
        )}
      </form>

      {successMessage && (
        <div className="mt-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4" role="alert">
          <p>{successMessage}</p>
        </div>
      )}
      {error && (
        <div className="mt-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4" role="alert">
          <p>{error}</p>
        </div>
      )}
    </div>
  );
};

export default TourForm;

import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { API_URL } from "../variables";
import { FaCheck, FaTimes } from 'react-icons/fa';
import { getToken } from '../authService';
import Loader from '../components/Loader'; 

const ReservedTickets = ({ setCount }) => {
  const [reservations, setReservations] = useState([]);
  const [packages, setPackages] = useState([]);
  const [loading, setLoading] = useState(true); 

  const fetchData = useCallback(async () => {
    const token = getToken();
    try {
      const [reservationsResponse, packagesResponse] = await Promise.all([
        axios.get(`${API_URL}/reservations/getAllReservations`, {
          headers: { "Authorization": `Bearer ${token}` },
        }),
        axios.get(`${API_URL}/package/getAllPackages`, {
          headers: { "Authorization": `Bearer ${token}` },
        }),
      ]);

      setReservations(reservationsResponse.data);
      setPackages(packagesResponse.data);
      if (setCount) setCount(reservationsResponse.data.length);
    } catch (error) {
      console.error('Error fetching data:', error.response || error.message);
    } finally {
      setLoading(false);
    }
  }, [setCount]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const getPackageTitle = (packageId) => {
    const tourPackage = packages.find((pkg) => pkg.package_id === packageId);
    return tourPackage ? tourPackage.package_title : "Unknown Package";
  };

  const handleAccept = async (reservationId, reservationEmail) => {
    try {
      const token = getToken();
      await axios.post(`${API_URL}/reservations/updateReservation`, {
        reservation_id: reservationId,
        status: 'accepted',
      }, {
        headers: { "Authorization": `Bearer ${token}` },
      });
      window.location.href = `mailto:${reservationEmail}?subject=Reservation Accepted&body=Your reservation has been accepted.`;
      fetchData();
    } catch (error) {
      console.error('Error accepting reservation:', error.response || error.message);
    }
  };

  const handleReject = async (reservationId) => {
    try {
      const token = getToken();
      await axios.post(`${API_URL}/reservations/deleteReservation`, {
        reservation_id: reservationId,
        status: 'rejected',
      }, {
        headers: { "Authorization": `Bearer ${token}` },
      });
      fetchData();
    } catch (error) {
      console.error('Error rejecting reservation:', error.response || error.message);
    }
  };

  return (
    <div className="mx-auto bg-gray-50">
      <h1 className="text-xl font-bold text-green-800 mb-4">Reservations</h1>
      {loading ? ( 
        <Loader />
      ) : (
        <div className="shadow-md rounded-lg">
          <table className="min-w-full bg-white border border-gray-200">
            <thead className="bg-green-800 text-white">
              <tr>
                <th className="px-3 py-2 text-left text-sm">No</th>
                <th className="px-3 py-2 text-left text-sm">Name</th>
                <th className="px-3 py-2 text-left text-sm">Email</th>
                <th className="px-3 py-2 text-left text-sm">Phone</th>
                <th className="px-3 py-2 text-left text-sm">Message</th>
                <th className="px-3 py-2 text-left text-sm">Tour Package</th>
                <th className="px-3 py-2 text-left text-sm">Date of Booking</th>
                <th className="px-3 py-2 text-left text-sm">Status</th>
              </tr>
            </thead>
            <tbody>
              {reservations.length > 0 ? (
                reservations.map((reservation, index) => (
                  <tr key={reservation.reservation_id} className="border-t border-gray-200">
                    <td className="px-3 py-2 text-sm">{index + 1}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_name}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_email}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_phone}</td>
                    <td className="px-3 py-2 text-sm">{reservation.reservation_message}</td>
                    <td className="px-3 py-2 text-sm">{getPackageTitle(reservation.package_id)}</td>
                    <td className="px-3 py-2 text-sm">{new Date(reservation.reservation_date).toLocaleDateString()}</td>
                    <td className="px-3 py-2 flex justify-between text-sm">
                      {reservation.reservation_status === 'accepted' || reservation.reservation_status === 'rejected' ? (
                        <span className={`font-bold ${reservation.reservation_status === 'accepted' ? 'text-green-600' : 'text-red-600'}`}>
                          {reservation.reservation_status.charAt(0).toUpperCase() + reservation.reservation_status.slice(1)}
                        </span>
                      ) : (
                        <>
                          <button className="text-green-600 hover:text-green-500" onClick={() => handleAccept(reservation.reservation_id, reservation.reservation_email)}>
                            <FaCheck />
                          </button>
                          <button className="text-gray-600 hover:text-red-500" onClick={() => handleReject(reservation.reservation_id)}>
                            <FaTimes />
                          </button>
                        </>
                      )}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center px-3 py-2 border-t border-gray-200 text-sm">
                    No reservations found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReservedTickets;

import React, { useState, useEffect, useCallback } from 'react';
import Activities from '../components/Activities';
import Destinations from '../components/Destinations';
import Loader from '../components/Loader';

function LocationsPage() {
  const [isLoading, setIsLoading] = useState(true);

  const loadData = useCallback(async () => {
    try {
      await new Promise(resolve => setTimeout(resolve, 1000));
      setIsLoading(false);
    } catch (error) {
      console.error('Data loading error:', error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return (
    <div className="container mx-auto px-4 py-6">
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <Loader />
        </div>
      ) : (
        <div className="grid md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-lg font-semibold text-gray-700">Destinations</h2>
            </div>
            <Destinations />
          </div>
          
          <div className="bg-white rounded-lg shadow-sm">
            <div className="p-6 border-b border-gray-100">
              <h2 className="text-lg font-semibold text-gray-700">Activities</h2>
            </div>
            <Activities />
          </div>
        </div>
      )}
    </div>
  );
}

export default LocationsPage;
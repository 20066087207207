import React, { useState } from "react";
import { useImage } from './ImageContext';
import axios from "axios";
import toast from "react-hot-toast";
import { API_URL } from "../variables";
import { getToken } from '../authService';

const TourCard = ({ tour, onDelete, onEdit, onDetailsClick }) => {
  const { getImageUrl } = useImage();
  const api_url = `${API_URL}/package/deletePackage`;
  const [showModal, setShowModal] = useState(false);

  // delete operation
  const handleDelete = async (tourId) => {
    try {
      const token = getToken(); 
      const response = await axios.post(
        api_url,
        {package_id: tourId},
        {
          headers: {
            'Authorization': `${token}`, 
          },
        }
      );
  
      if (response.status === 200) {
        onDelete(tourId); 
        toast.success("Tour deleted successfully.");
        window.location.reload(); 
      }
    } catch (error) {
      console.error("Failed to delete the tour:", error);
      toast.error("Failed to delete the tour. Please try again.");
    }
  };

  const confirmDelete = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleConfirmDelete = () => {
    handleDelete(tour.package_id);
    closeModal();
  };

  return (
    <div className="p-6 bg-white border border-gray-200 rounded-lg shadow-md flex flex-col md:flex-row items-center space-y-4 md:space-y-0 md:space-x-6 hover:shadow-lg transition-shadow duration-200 ease-in-out">
      <img
        src={getImageUrl(tour.package_image)}
        alt={tour.package_title}
        className="w-full md:w-48 h-32 object-cover rounded-md"
      />
      <div className="flex-1">
        <div className="tour-info mb-4">
          <p className="text-gray-600 text-sm font-medium mb-2">
            {tour.package_location}, {tour.package_country}
          </p>
          <h3 className="text-2xl font-semibold mb-2">{tour.package_title}</h3>
          <p className="text-gray-500 text-sm mb-4">{tour.package_summary}</p>
        </div>
        <div className="tour-details flex justify-between items-center">
          <div>
            <p className="text-gray-700 text-sm">
              {tour.package_days} Days, {tour.package_days - 1} Nights
            </p>
            <p className="text-green-800 font-semibold text-lg mt-2">${tour.package_price}</p>
            <div className="tour-special text-sm mt-4 space-x-4">
              <span className="px-2 py-1 bg-green-100 text-green-800 rounded-lg">
                Best Price Guarantee: {tour.best_price_guarantee === 1 ? 'Yes' : 'No'}
              </span>
              <span className="px-2 py-1 bg-green-100 text-green-800 rounded-lg">
                Free Cancelation: {tour.free_cancelation === 1 ? 'Yes' : 'No'}
              </span>
              <span className="px-2 py-1 bg-green-100 text-green-800 rounded-lg">
                Popular: {tour.is_popular === 1 ? 'Yes' : 'No'}
              </span>
            </div>
          </div>
          <div className="space-x-4 flex items-center">
            <button
              className="px-4 py-2 bg-green-800 text-white font-semibold rounded hover:bg-green-600 transition duration-200"
              onClick={onDetailsClick}
            >
              Details
            </button>
            {onEdit && (
              <button
                className="px-4 py-2 bg-yellow-400 text-white font-semibold rounded hover:bg-yellow-600 transition duration-200"
                onClick={() => onEdit(tour)}
              >
                Edit
              </button>
            )}
            {onDelete && (
              <button
                className="px-4 py-2 bg-red-700 text-white font-semibold rounded hover:bg-red-600 transition duration-200"
                onClick={confirmDelete}
              >
                Delete
              </button>
            )}
          </div>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center">
          <div className="bg-white p-6 rounded shadow-lg">
            <h3 className="text-lg font-semibold mb-4">Are you sure you want to delete this tour?</h3>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-500 text-white rounded hover:bg-gray-600"
                onClick={closeModal}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-700 text-white rounded hover:bg-red-600"
                onClick={handleConfirmDelete}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default TourCard;
